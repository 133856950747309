@import "../../../../../assets/stylesheet/global/varibale";

.unread-notification-wrapper {
  color: $font-default-color;
  font-size: 16px;

  .unread-notification {
    color: $pink-color;
  }
}

.notification-list {
  max-height: calc(100vh - 220px);
  overflow-y: auto;

  .notification-card {
    cursor: pointer;
    font-size: 14px;
    padding: 10px 15px;

    &:hover {
      background-color: white;
      border-radius: 5px;
      box-shadow: 0 2.5px 8px 0 rgba(246, 208, 195, .33);
    }

    .rejected{
      color: #ff1313;
    }

    .request-id {
      color: #3064e8;
    }

    &.unread {
      font-family: VoltePlay_Medium, sans-serif;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        border-radius: 50%;
        top: 11px;
        left: 1px;
        width: 8px;
        height: 7.5px;
        box-shadow: 0 2px 5px 0 rgba(242, 103, 175, .93);
        background-image: radial-gradient(circle at 0 0, #e8308f, #fb87c3);
      }
    }
  }
}
