@import "mixin";

* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #e8dcdb;
  color: $font-default-color;
  font: 14px/1.33 "VoltePlay_Regular",sans-serif;
}

a,
i,
span,
strong {
  display: inline-block;
}
ul,
li {
  list-style-type: none;
}
ul {
  padding-left: 0;
}

a,
input,
button,
textarea {
  outline: none;

  &:focus {
    outline: none;
  }
}
a {
  &:hover {
    text-decoration: none;
  }
}

hr {
  background: none;
  border: 0 none;
  border-top: 1.3px dashed #b7b7b7;
  height: 0;
  margin-top: 10px;
  margin-bottom: 10px;
  opacity: .66;

  &.no-top {
    margin-top: 0;
  }

  &.no-bottom {
    margin-bottom: 0;
  }

  &.no-space {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.extra-space {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.medium-strong {
  font-family: VoltePlay_Medium, sans-serif;
}

.wrapper {
  @include header-footer-wrapper;
  @media screen and (max-width: 767px){
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
}

.content-wrapper {
  @include wrapperContent;
  padding-top: 120px;

  @include break-point(tablet-view) {
    padding-top: 175px;
  }
}

.heading {
  @include page-heading;
}

.sub-heading {
  font-size: 16px;
  line-height: 20px;
}

.pos-rel {
  position: relative!important;
}

.text-upper {
  text-transform: uppercase;
}

.text-camel {
  text-transform: capitalize;
}

.error-text, .unsupported-message {
    color: $red;
}

.link-blue {
  color: $link-color;
  cursor: pointer;
}

.file-viewer-modal{
  overflow-y: auto;
  max-width: calc(900px - 40px) !important;
  max-height: calc(100vh - 40px);
}
.f-left {
  float: left;
}