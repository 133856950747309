.stepper-wrap {
    margin-bottom: 15px;
    .step {
        width: 25%;
        display: inline-block;
        position: relative;
        font-size: 13px;
        &::after {
            content: "";
            height: 4px;
            border: 1px solid #e50078;
            background-color: white;
            top: 100%;
            left: 0;
            width: 100%;
            position: absolute;
        }
        &.active {
            color: #e50078;
            background-color: transparent;
            font-weight: 600;
            &::after {
                background-color: #e50078;
            }
        }
    }
}
.Partner-creation-form {
    .custom-modal-header {
        padding-bottom: 8px;
    }
    padding-top: 5px !important;
    .form-page-wrapper {
        max-height: calc(100vh - 175px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;
    }
    .bottom-action-btn {
        .button {
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .form-element {
        margin-bottom: 0;
        .input-group-element {
            padding-top: 20px;
            padding-bottom: 10px;
            .input-element {
                padding-left: 10px;
                padding-top: 2px;
                padding-bottom: 1px;
                height: 28px;
            }

            .label-title {
            }
        }
        &.textarea-field {
            .input-group-element {
                .input-element {
                    height: 50px;
                }
            }
        }
        &.select-control {
            &:after {
                top: 30px;
            }
        }
        &.error {
            .invalid-text {
                margin-top: -10px;
            }
        }
        &.blue {
            .input-element {
                background-color:rgba(#4a9ef8, 0.09);
            }
        }
        &.grey {
            .input-element {
                background-color:rgba(#808080, 0.09);
            }
        }
    }
    .bottom-action-btn {
        padding-top: 10px;
        padding-bottom: 0;
    }
    .button.big{
        line-height:20px;
        min-height:36px;
        font-size: 14px;
    }
}
.custom-modal.overlay.partner-creation-overlay{
    background-color: rgba(0, 0, 0, 0.5);
}
