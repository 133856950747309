@import "./../../assets/stylesheet/global/varibale";
@import "./../../assets/stylesheet/global/mixin";

.right-panel-wrapper {
  border-radius: 5px;
  box-shadow: 0 0 24.5px 0 #e0cdcc;
  border: solid 2px #ffffff;
  background-color: rgba(255, 255, 255, 0.75);
  margin-bottom: 68px;
}

@media screen and (max-width: 767px) {
  .right-panel-wrapper {
    margin-bottom: 32px;
    box-shadow: none;
    border: 0;
    background: transparent;
    border-radius: 0;
  }
}

.search-container {
  .form-element {
    .search-input {
      width: 30px;
      box-sizing: border-box;
      border: 0;
      border-radius: 5px;
      font-size: 16px;
      background: transparent url('./../../assets/images/search.png') no-repeat 10px 15px;
      background-size: 17px 17px;
      padding: 12px 20px 10px 30px;
      -webkit-transition: width 0.4s ease-in-out;
      transition: width 0.4s ease-in-out;
      transform: translateX(300px);
      @include break-point(mobile-view) {
        transform: translateX(0px);
        width: 100%;
        background: $white url('./../../assets/images/search.png') no-repeat 8px 12px;
        background-size: 17px 17px;
      }
      &:focus {
        border: solid 1px #1d9eff;
        background: $white url('./../../assets/images/search.png') no-repeat 8px 12px;
        background-size: 17px 17px;
        width: 300px;
        transform: translateX(160px);
        @include break-point(mobile-view) {
          transform: translateX(0px);
          width: 100%;
        }
      }
    }
  }
}
