@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.pending-doc-section {
  background-color: #ece8e5;
  border-radius: 5px;
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  margin: 20px 40px 0;

  .value {
    color: $pink-color;
    margin-left: 5px;
  }
}

.button-list-wrapper {
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;

  .close-popup {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 21px;
    display: none;

    svg {
      margin-left: 6px;
    }
  }

  @include break-point(mobile-view) {
    background-color: $white;
    position: fixed;
    right: 0;
    bottom: -999px;
    left: 0;
    padding: 60px 40px 20px 40px;
    opacity: .95;
    transition: all 0.6s;
    z-index: 999;

    &.active {
      bottom: 0;
    }
    .close-popup {
      display: flex;
      align-items: center;
      font-size: 16px;
    }
  }
}

.activity-list-wrapper {
  margin-top: 40px;
  margin-right: 15px;
  margin-bottom: 50px;
  max-height: 380px;
  overflow-y: auto;
  padding-right: 18px;
  padding-left: 20px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(#7580a0, .5);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .title {
    font-size: 16px;
    font-family: "VoltePlay_Medium", sans-serif;
    font-weight: normal;
    color: $pink-color;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .activity-item {
    position: relative;
    padding-left: 20px;
    font-size: 14px;
    padding-bottom: 15px;

    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      left: 1px;
      top: 5px;
      border: solid 2px $font-default-color;
      background-color: #f4f4f4;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      border: 1px dashed $font-default-color;
      top: 15px;
      bottom: -5px;
      left: 5px;
      opacity: .4;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    .assigned-by {
      color: #3064e8;
    }

    .assigned-to {
      margin-bottom: 5px;
    }

    .rejected {
      text-transform: uppercase;
      color: #f22463;
    }

    .comments {
      padding-bottom: 8px;
      font-family: "VoltePlay_Regular", sans-serif;
      font-style: italic;
    }

    .attachment {
      position: relative;
      color: #3064e8;
      padding-left: 14px;

      &:before {
        content: "";
        position: absolute;
        width: 9px;
        height: 13px;
        background-image: url("../../../assets/images/attachment.svg");
        background-size: 9px 13px;
        background-repeat: no-repeat;
        top: 2px;
        left: 0;
      }
    }
  }
}

.partner-details-wrapper {
  padding-left: 20px;
  padding-right: 18px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 91px;
    height: 101px;
    background-image: url('../../../assets/images/tata-sky-listing.png');
    background-repeat: no-repeat;
    background-size: 91px 101px;
    top: 52px;
    right: 20px;
  }

  .title {
    font-size: 16px;
    font-family: "VoltePlay_Medium", sans-serif;
    font-weight: normal;
    color: $pink-color;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .partner-detail-item {
    margin-bottom: 15px;
    margin-right: 90px;

    .label {
      color: $light-font;
    }

    .value {
      font-size: 35px;
      line-height: 0.97;
      margin-bottom: 20px;

      @include break-point(mobile-view) {
        font-size: 24px;
      }
    }
  }
}

.take-action {
  display: none;
}

@media screen and (max-width: 767px) {
  .take-action {
    background-image: linear-gradient(to left, #e40039, #e5007d);
    color: $white;
    display: block;
    font-size: 16px;
    padding: 15px;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    transition: all 0.6s;
    z-index: 999;

    &.active {
      bottom: -60px;
    }
  }
}