@import "./../../assets/stylesheet/global/varibale";
@import "./../../assets/stylesheet/global/mixin";

.about-us-container {
  background-color: $white;
  border-radius: 5px;
  box-shadow: 0 0 24.5px 0 #f2ebee;
  padding: 30px 30px 100px 55px;

  @include break-point(mobile-view) {
    padding: 20px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-top: 45px;
    padding-bottom: 120px;

    @include break-point(mobile-view) {
      flex-direction: column;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .sub-title {
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 24px;

      @include break-point(mobile-view) {
        font-size: 20px;
      }
    }

    .details {
      font-size: 19px;
      line-height: 1.4;

      @include break-point(mobile-view) {
        font-size: 16px;
      }
    }

    .documents {
      border: 1px solid $grey;
      padding: 20px;

      @include break-point(mobile-view) {
        margin-top: 20px;
      }

      .inner {
        display: flex;

        @include break-point(mobile-view) {
          flex-direction: column-reverse;

          .link-options {
            margin-bottom: 0;
            padding-right: 20px;
            padding-top: 20px;
          }
        }
      }

      .link-options {
        padding-right: 120px;
        .type {
          cursor: pointer;
          font-size: 19px;
          margin-bottom: 15px;
          text-decoration: underline;
        }
      }

      .doc-icon {
        margin-top: -6px;
      }
    }
  }

  .trade-mark-info {
    font-size: 16px;
    line-height: 24px;
    width: 70%;

    @include break-point(tablet-view) {
      width: 100%;
    }

    @include break-point(mobile-view) {
      text-align: justify;
      width: 100%;
    }
  }
}

.logo {
  cursor: pointer;
  // height: 41px;
  height:auto;
  width: 154px;
  margin-top: 25px;
  margin-bottom: 20px;

  @include break-point(tablet-view) {
    height: 30px;
    margin-top: 10px;
    margin-right: 30px;
  }

  @include break-point(mobile-view) {
    height: 28px;
    width: 110px;
  }
}
