@import "./../../../assets/stylesheet/global/varibale";
@import "./../../../assets/stylesheet/global/mixin";

.document-list-container {
  max-height: calc(100% - 150px);
  overflow-y: auto;
  width: 100%;

  @include break-point(mobile-view) {
    max-height: calc(100% - 200px);
  }

  .label {
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 19px;
    display: inline-flex;

    &.serial {
      width: 34px;
    }
  }

  .document-type {
    .dashed-border {
      border-top: 1px dashed #ccc;
      border-left: 1px dashed #ccc;
      display: inline-flex;
      flex-direction: column;
      margin-right: 30px;
      padding-bottom: 20px;
      width: calc(100% - 64px);

      @include break-point(mobile-view) {
        margin-right: 10px;
        width: calc(100% - 44px);
      }

      .content {
        display: inline-flex;
        justify-content: space-between;
        padding: 24px 0 0 20px;
        position: relative;

        .doc-list {
          margin-bottom: 0;
          padding-top: 10px;
          padding-bottom: 10px;

          li {
            background-color: $white;
            border-radius: 5px;
            box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
            color: #3064e8;
            display: inline-flex;
            margin-right: 8px;
            padding: 5px 7.5px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .declaration-info {
          font-family: VoltePlay_Medium, sans-serif;
          font-size: 14px;
          padding-top: 20px;
        }

        .action-btn-group {
          align-items: center;
          background-color: $white;
          border-radius: 5px;
          box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, 0.45);
          display: inline-flex;
          flex-direction: column;
          height: 82px;
          justify-content: center;
          width: 125px;

          .button {
            background-color: #f9f8f8;
            color: $font-default-color;
            padding: 6px 8px;
            text-align: left;
            text-transform: capitalize;
            width: 120px;

            &:first-child {
              margin-bottom: 2.5px;
            }

            &.approved {
              background-color: #0dc530;
              color: $white;
            }

            &.reject {
              background-color: #f12043;
              color: $white;
            }

            &.disabled,
            &[disabled] {
              color: rgba(53, 64, 96, 0.66);
            }
          }

          .icon-circle {
            align-items: center;
            background-color: $white;
            border-radius: 50%;
            box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
            display: inline-flex;
            height: 25px;
            justify-content: center;
            margin-right: 8px;
            vertical-align: middle;
            width: 25px;
          }
        }

        @include break-point(mobile-view) {
          flex-direction: column;
          padding-left: 15px;

          .declaration-info {
            font-family: VoltePlay_Regular, sans-serif;
            padding-top: 0;
            padding-bottom: 15px;
          }

          .action-btn-group {
            flex-direction: row;
            height: 50px;
            justify-content: space-between;
            padding: 2px 5px 0 5px;
            width: 100%;

            .button {
              &:first-child {
                margin-right: 10px;
              }
            }
          }
        }
      }

      .textarea-field {
        margin-top: 10px;
        margin-bottom: -20px;
        margin-left: 20px;

        @include break-point(mobile-view) {
          margin-top: 15px;
        }
      }
    }
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2.5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($font-default-color, .3);
    border-radius: 1.5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

.bottom-action-btn {
  background-color: $white;
  box-shadow: 0 2.5px 31px 0 #dabfb6;
  padding-top: 24px;
  padding-bottom: 18px;
  position: fixed;
  left: 0;
  bottom: 0;
  text-align: center;
  width: 100%;

  .button {
    width: 230px;

    &:first-child {
      margin-right: 15px;

      @include break-point(mobile-view) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }

  @include break-point(mobile-view) {
    padding-bottom: 105px;

    .button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

.select-assignee-input {
  .input-group-element {
    .input-element {
      padding: 0 15px;
    }
  }
}