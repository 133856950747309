@import "./../../../assets/stylesheet/global/varibale";
@import "./../../../assets/stylesheet/global/mixin";

.custom-modal {
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;

  .close-icon {
    cursor: pointer;
    line-height: 0;
    position: absolute;
    top: 15px;
    right: 15px;
    vertical-align: middle;

    &:hover {
      svg {
        path {
          fill: $font-default-color;
        }
      }
    }
  }

  &-content {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 2.5px 42px 0 rgba(90, 83, 83, 0.75);
    cursor: default;
    margin-bottom: 50px;
    max-width: 400px;
    min-height: 150px;
    padding: 15px 20px 25px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &.right-view-modal {
      background-color: #fcf9f8;
      border: solid 2px #ffffff;
      border-radius: 20px 0 0 0;
      box-shadow: 0 0 24.5px 0 #f2ebee;
      height: calc(100vh - 80px);
      max-width: 600px;
      padding-left: 25px;
      top: 80px;
      right: 0;
      left: unset;
      transform: translateX(0);

      .close-icon {
        top: 30px;
        right: 50px;

        @include break-point(mobile-view) {
          top: 20px;
          right: 20px;
        }
      }

      @include break-point(mobile-view) {
        padding-left: 10px;
      }

      &.notification {
        max-width: 430px;
        padding-left: 25px;
      }
    }
    &.Partner-creation-form {
      background-color: #fcf9f8;
      border: solid 2px #ffffff;
      // border-radius: 20px 0 0 0;
      box-shadow: 0 0 24.5px 0 #5c5c5c;
      height: calc(100vh - 20px);
      max-width: 800px;
      padding-left: 25px;
      top: 10px;
      right: 50%;
      transform: translateX(50%);
      left: unset;
      // transform: translateX(0);

      .close-icon {
        // top: 30px;
        // right: 50px;

        @include break-point(mobile-view) {
          // top: 20px;
          // right: 20px;
        }
      }

      @include break-point(mobile-view) {
        // padding-left: 10px;
      }

      &.notification {
        max-width: 430px;
        padding-left: 25px;
      }
    }

    &.full-width {
      background-color: #eceff7;
      padding: 30px 40px;
      max-width: 1000px;

      .table-wrapper {
        thead {
          tr {
            th {
              &:last-child {
                width: 300px;
              }
            }
          }
        }
      }
    }

    @include break-point(mobile-view) {
      width: calc(100% - 40px);
    }
  }

  &-dialog {
    position: relative;
  }

  &-header {
    color: $light-font;
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 18px;

    .title {
      color: $font-default-color;
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 19px;
      padding-top: 8px;
    }

    .sub-title {
      font-family: VoltePlay_Regular, sans-serif;
      font-size: 16px;

      .pending {
        color: $pink-color;
      }
    }
    
    &.center {
      text-align: center;
    }

    .gradient-text {
      display: inline-block;
      // background-clip: text;
      // -webkit-background-clip: text;
      // background-image: linear-gradient(to right, #f08300, #e3000f 35%, #a72879 80%, #064497), linear-gradient(to bottom, #0092ed, #0092ed);
      // -webkit-text-fill-color: transparent;
      color:#E10092;
      font-size: 24px;
      padding-top: 20px;
      text-align: center;

      @include break-point(ie) {
        @include ie-text-gradient;
      }

      .value {
        font-size: 24px;
        opacity: 0.8;
        -webkit-text-fill-color: $font-default-color;
      }
    }
  }

  &.show {
    display: block;
  }
  .text-center{
    text-align: center;
  }

  .sub-heading {
    background-image: linear-gradient(to top, #ea1b2d, #a63283);
    display: inline-block;
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 27px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    @include break-point(ie) {
      @include ie-text-gradient;
    }
  }

  &.overlay {
    background-color: rgba(255,255,255, .5);
  }
}

.activity-detail-wrapper{
  .custom-modal-content{
    max-width: 600px;
    > .text-center{
      padding: 0;
    }
  }
}