@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.tooltip-element {
  background-color: #4b577a;
  border-radius: 5px;
  box-shadow: 0 4.5px 8px 0 rgba(38, 47, 75, .38);
  color: #b9c4e3;
  display: none;
  font-size: 16px;
  line-height: 20px;
  padding: 13px 20px 13px 15px;
  position: absolute;
  top: -2px;
  right: -280px;
  text-align: left;
  width: 260px;
  z-index: 99;

  @include break-point(mobile-view) {
    font-size: 12px;
    padding: 8px 10px 8px 10px;
    top: 40px;
    right: -15px;
  }

  &:after {
    width: 0;
    height: 0;
    border-top: 13px inset transparent;
    border-bottom: 13px solid transparent;
    border-right: 13px inset #4b577a;
    position: absolute;
    content: "";
    top: 13px;
    left: -12px;

    @include break-point(mobile-view) {
      border-left: 13px solid transparent;
      border-right: 13px solid transparent;
      border-bottom: 13px solid #4b577a;
      top: -20px;
      right: 22px;
      left: unset;
    }
  }
}