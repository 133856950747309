
.landing-wrapper {
    border-radius: 5px;
    box-shadow: 0 0 24.5px 0 #e0cdcc;
    border: solid 2px #ffffff;
    background-color: rgba(255, 255, 255, 0.75);
    height: calc(100vh - 150px);

    .left-section {
        padding-top: 100px;
        background-image: url("./../../assets/images/dashboard-divider.jpg");
        background-color: #f6ecec;
        background-repeat:no-repeat;
        background-position: right top;
        background-size: contain;

        .shop-icon {
            background: url("./../../assets/images/shop-icon.png") no-repeat;
            width: 88px;
            height: 72px;
        }

        .welcome {
            font-size: 32px;
            line-height: 1;
        }
    }

    .right-section {
        padding-top: 160px;
        width: 100%;
        background-image: url("./../../assets/images/abstract_swrills.svg");
        background-color: #fcf8f8;
        background-repeat:no-repeat;
        background-position: right top;
        background-size: 52%;
        
        .action-button {
            font-family: 'VoltePlay_Regular';
            width: 400px;
            text-align: left;
            padding: 10px 15px;
            padding-right: 20px;

            span {
                display: block;
                font-size: 12px;

                &.label {
                    background-image: url("./../../assets/images/arrow-icon.png");
                    background-repeat:no-repeat;
                    background-position: right center;
                    font-size: 24px;
                    margin-bottom: 0px;
                    opacity: .8;
                }
                .status {
                    color: #000000;
                    width: 90%;
                    text-align: center;
                }
            }
        }
        .button.disabled:hover, .button[disabled]:hover {
            background-color: #ccc;
            border-color: #ccc;
            color: #fff;
            opacity: 1;
            cursor: not-allowed;
        }
        .store {
            .button {
                background-color: #e50078;

                &:hvoer {
                    opacity: 0.8;
                }
            }
        }
    }
}