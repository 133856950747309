@import "../../../../assets/stylesheet/global/varibale";
.task-list-pagination-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 28px;
  padding-right: 32px;

  .task-list-pagination {
    li {
      display: inline-block;
      > a {
        font-size: 14px;
        position: relative;
        float: left;
        padding: 6px 12px;
        color: #8b93ac;
        text-decoration: none;
        cursor: pointer;
      }

      &.active {
        &::after{
            content:none;
          }
        > a {
          cursor: default;
          font-family: "VoltePlay_Bold", sans-serif;
          border: solid 1px #959595;
          border-radius: 50%;
          color: $pink-color;
          width: 32px;
          height: 32px;
          text-align: center;
          line-height: 30px;
          padding: 0;
        }
      }

      &.disabled {
        > a {
          cursor: not-allowed;
        }
      }

      &.previous {
        position: relative;
        padding-left: 10px;

        &:before {
          content: "";
          position: absolute;
          width: 16px;
          height: 7px;
          background-image: url("../../../../assets/images/arrow.svg");
          background-size: 16px 7px;
          top: 50%;
          transform: translateY(-50%) rotate(-180deg);
          left: 0;
        }
        > a {
          color: #3064e8;
        }
      }

      &.next {
        position: relative;
        padding-right: 10px;

        &:after {
          content: "";
          position: absolute;
          width: 16px;
          height: 7px;
          background-image: url("../../../../assets/images/arrow.svg");
          background-size: 16px 7px;
          top: 50%;
          transform: translateY(-50%);
          right: 0;
        }
        > a {
          color: #3064e8;
        }
      }
    }
  }

  .showing-result-form {
    label {
      font-size: 14px;
      margin-right: 20px;
    }

    select {
      width: 68px;
      height: 38px;
      padding-left: 10px;
      border-radius: 6px;
      border: solid 2px #f2f1f1;
      background-color: #ffffff;
      font-size: 17px;
      line-height: 34px;
    }
  }
}

@media screen and (max-width: 767px) {
  .task-list-pagination-wrapper {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;

    .task-list-pagination {
      margin-bottom: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        &.previous {
          padding-left: 0;

          > a {
            padding-left: 0;
          }

          &:before {
            content: none;
          }
        }

        &.next {
          padding-right: 0;

          > a {
            padding-right: 0;
          }

          &:after {
            content: none;
          }
        }
        
        &:nth-child(6) {
          & ~ li:not(:last-child){
            display: none;
          }
          & ~ li.active, ~ li.break-me{
            display: block;
          }
        }
      }
    }
    .showing-result-form {
      label {
        margin-right: 8px;
        text-transform: uppercase;
      }
      select {
        vertical-align: middle;
        height: 35px;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
