@import "./../../../assets/stylesheet/global/varibale";
@import "./../../../assets/stylesheet/global/mixin";

.button {
  background-color: transparent;
  border-radius: 5px;
  border-width: 0;
  color: $white;
  cursor: pointer;
  font-family: VoltePlay_Medium, sans-serif;
  font-size: 16px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;

  &.shadow {
    box-shadow: 0 2.5px 4px 0 rgba(247, 170, 206, .6);
    -webkit-box-shadow: 0 2.5px 4px 0 rgba(247, 170, 206, .6);
    -moz-box-shadow: 0 2.5px 4px 0 rgba(247, 170, 206, .6);
  }

  &.full {
    width: 100%;
  }

  &.fill {
    background-color: $link-color;
  }

  &.action {
    background-color: #e50078;
  }

  &.big {
    min-height: 48px;
  }

  &.calculate {
    max-height: 46px;
  }

  &.disabled,
  &[disabled] {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    cursor: default;
  }

  &.no-radius {
    border-radius: 0;
  }

  &.border {
    border: 1px solid $grey;
    color: $font-default-color;
  }

  &.white-bg {
    background-color: $white;
  }

  &:hover {
    opacity: .8;
  }

  &.w-210 {
    width: 210px;

    @include break-point(mobile-view) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  &.add-btn {
    display: inline-flex;
    flex-direction: column;
    font-size: 10px;
    height: 48px;
    width: 48px;

    .text {
      margin-left: -6px;
    }

    &.remove {
      align-items: center;
      background-color: #e50078;
      display: inline-flex;
      justify-content: center;

      &[disabled] {
        background-color: $grey;
        border-color: $grey;
        color: $white;
        cursor: default;
      }
    }

    @include break-point(mobile-view) {
      height: 36px;
      min-height: 36px;
      line-height: 1;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
}

.text-btn {
  color: $pink-color;
  cursor: pointer;
  font-family: VoltePlay_Medium, sans-serif;
  opacity: 0.8;

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }
}