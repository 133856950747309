@import "varibale";

// font face mixin

@mixin font-type($name, $location) {
  @font-face {
    font-family: $name;
    src: url($location)
  }
}

// conditional scss for media query
@mixin break-point($point) {
  @if $point == tablet-view {
    @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $point == mobile-view {
    @media screen and (max-width: 767px) {
      @content;
    }
  } @else if $point == ie {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
}

@mixin gradient-font-style {
  // background: linear-gradient(to right, rgba(239,133,34,1) 0%, rgba(226,30,37,1) 29%, rgba(158,39,120,1) 62%, rgba(25,72,147,1) 100%);
  // background: -webkit-linear-gradient(left, rgba(239,133,34,1) 0%, rgba(226,30,37,1) 29%, rgba(158,39,120,1) 62%, rgba(25,72,147,1) 100%);
  // background: -o-linear-gradient(left, rgba(239,133,34,1) 0%, rgba(226,30,37,1) 29%, rgba(158,39,120,1) 62%, rgba(25,72,147,1) 100%);
  // background: -ms-linear-gradient(left, rgba(239,133,34,1) 0%, rgba(226,30,37,1) 29%, rgba(158,39,120,1) 62%, rgba(25,72,147,1) 100%);
  color:#E10092;
  display: inline-block;
  font-family: VoltePlay_Medium, sans-serif;
  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
}

@mixin page-heading {
  @include gradient-font-style;
  font-size: 35px;
  font-weight: 400;
  line-height: 34px;
  margin-bottom: 10px;

  @include break-point(tablet-view) {
    font-size: 30px;
  }

  @include break-point(mobile-view) {
    font-size: 25px;
  }

  @include break-point(ie) {
    @include ie-text-gradient;
  }
}

@mixin header-footer-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: $wrapper-size; //actual width 1260 + 30 padding
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  z-index: 999;
}

@mixin wrapperContent {
  margin-right: auto;
  margin-left: auto;
  max-width: $content-wrapper-size; //actual width 1140 + 30 padding
  min-height: calc(100vh - 207px);
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
}

@mixin ie-text-gradient {
  background: none;
  color: #ea1b2d;
}

@mixin disabled-element {
  background-color: #f3e9e9;
  color: #6e655c;
  pointer-events: none;
}
