@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.auth-container {
  background: url("./../../../assets/images/bg_login.jpg") no-repeat 0 0;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  padding-top: 50px;
  padding-left: 150px;
  width: 100%;

  @include break-point(mobile-view) {
    background-size: 100% 1000px;
    padding-top: 100px;
    padding-left: 10px;
  }

  @include break-point(tablet-view) {
    background-size: 100% 1000px;
    padding-left: 50px;
    padding-top: 150px;
  }

  .login-logo {
    // height: 61px;
    max-width: 231px;
    width: 100%;

    @include break-point(mobile-view) {
      height: 51px;
      max-width: 192px;
      padding-left: 10px;
    }
  }

  .inner {
    background: url("./../../../assets/images/abstract_swrills.svg") no-repeat #f7f7f7 115px -10px;
    border-radius: 5px;
    box-shadow: 0 0 24.5px 0 #ccc;
    border: solid 2px $white;
    max-width: 383px;
    min-height: 400px;
    padding: 30px;
    position: relative;
    width: 100%;
    z-index: 99;

    .back-btn-link {
      position: absolute;
      bottom: 30px;
      left: 30px;
    }

    .main-title {
      font-size: 22px;
    }

    .description {
      color: $light-font;
      font-size: 16px;

      .value {
        color: $font-default-color;
      }
    }

    @include break-point(mobile-view) {
      max-width: 300px;
    }
  }

  .outer-layer {
    position: relative;

    @include break-point(mobile-view) {
      margin-right: 10px;
      margin-left: 10px;
    }

    .layer-strip {
      background-color: #F2F0F1;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 0 24.5px 0 #ccc;
      height: 10px;
      max-width: 363px;
      padding-left: 10px;
      padding-right: 10px;
      position: absolute;
      bottom: -9px;
      left: 10px;
      width: 100%;
      z-index: 98;

      @include break-point(mobile-view) {
        max-width: 283px;
        width: calc(100% - 20px);
      }
    }
  }
}

.notes {
  color: $light-font;
  text-align: center;

  .title {
    color: $link-color;
    cursor: pointer;
    position: relative;

    &:before {
      background-color: $link-color;
      content: "";
      height: 1px;
      position: absolute;
      bottom: 1px;
      left: 0;
      width: 100%;
      transform: scaleX(0);
      -webkit-transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
      -webkit-transition: all 0.3s ease-in-out 0s;
      visibility: hidden;
    }

    &:hover {
      &:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }

    &.small {
      text-transform: unset;
      font-size: 14px;
      padding-bottom: 0;
    }
  }

  &.align-left {
    text-align: left;
  }
}

.server-msg {
  color: #d32f0e;
  font-family: VoltePlay_Medium, sans-serif;
  font-size: 16px;
  padding-bottom: 10px;
  text-align: center;
}

.prev-link {
  color: $link-color;
  cursor: pointer;
  display: inline-block;
  font-family: VoltePlay_Medium, sans-serif;
  font-size: 16px;
  position: absolute;
  bottom: 30px;
  left: 30px;

  .icon {
    margin-right: 8px;
    vertical-align: sub;
    transform: rotate(180deg);
  }
}

.otp-screen {
  &:first-of-type {
    hr {
      display: none;
    }
  }
}

.navbar {
  margin-top: 30px;
  max-width: 383px;
  text-align: center;

  .option {
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 16px;

    a {
      color: $font-default-color;
      padding-bottom: 5px;
      text-decoration: underline;

      &:hover {
        color: rgba($font-default-color, 0.7);
      }
    }

    .nav-icon {
      margin-right: 10px;
      line-height: 0;
      vertical-align: middle;
    }
  }
}
