@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.common-left-right-padding {
  padding-left: 26px;
  padding-right: 26px;
  @include break-point(mobile-view){
    padding-left: 15px;
    padding-right: 15px;
  }
}

.common-heading {
  font-size: 16px;
  font-family: "VoltePlay_Medium", sans-serif;
  font-weight: normal;
  color: $pink-color;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.top-wrap {
  @extend .common-left-right-padding;
  margin-top: 30px;
  margin-bottom: 34px;
  display: flex;
  justify-content: space-between;

  .back-btn {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    font-family: "VoltePlay_Medium", sans-serif;
    font-size: 14px;
    color: #3064e8;
    vertical-align: text-bottom;

    .icon {
      transform: rotate(180deg) translateX(12px);
    }
    @include break-point(mobile-view) {
      display: block;
      .icon {
        transform: rotate(180deg) translateX(0);
        margin-right: 8px;
      }
    }
  }

  .id-wrap {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 1px;
      background-color: #ded0d8;
      top: 6px;
      bottom: 4px;
      left: -5px;
    }
    @include break-point(mobile-view) {
      display: block;
      position: inherit;
    }
  }

  .request-id-wrap {
    font-size: 19px;
  }

  .request-id {
    font-family: "VoltePlay_Medium", sans-serif;
    color: $pink-color;
  }

  .created-date {
    font-size: 16px;
    color: $light-font;
  }

  .top-right {
    text-align: right;
    @include break-point(mobile-view) {
      text-align: left;
      margin-top: 15px;
    }
  }

  .assigned-on {
    font-size: 14px;
    display: block;

    .assigned-date {
      font-family: "VoltePlay_Medium", sans-serif;
      color: $pink-color;
    }
  }

  .name {
    font-size: 16px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 11px;
      height: 13px;
      background-image: url("../../../assets/images/assigned-to-user-icon.svg");
      background-size: 11px 13px;
      top: 4px;
      left: -16px;
    }
    @include break-point(mobile-view){
      margin-left: 16px;
    }
  }
  @include break-point(mobile-view){
    display: block;
  }
  .total-score {
    h3 {
      font-size: 20px;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  }
}

.firm-basic-info {
  @extend .common-left-right-padding;
  margin-bottom: 40px;

  .previous-partner-logo {
    width: 100%;
    overflow-wrap: break-word;
    position: relative;

    // &:after {
    //   content: "";
    //   width: 17px;
    //   height: 17px;
    //   background-image: url("../../../assets/images/tick-icon.png");
    //   background-size: 17px 17px;
    //   position: absolute;
    //   top: 50%;
    //   margin-left: 10px;
    //   transform: translateY(-50%);
    // }
  }

  .firm-address {
    font-size: 16px;
    margin-bottom: 10px;
    width: 100%;
    word-wrap: break-word;
  }

  .firm-contact {
    display: flex;

    .common-span {
      position: relative;
      font-size: 16px;
      padding-left: 30px;
      margin-right: 15px;

      &:before {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background-image: url("../../../assets/images/call.png");
        background-size: 25px 25px;
        left: 0;
      }
    }

    .email {
      color: $link-color;
      text-decoration: underline;

      &:before {
        background-image: url("../../../assets/images/email.png");
      }
    }
    @include break-point(mobile-view) {
      display: block;
      .email,
      .contact {
        margin-bottom: 15px;
      }
    }
  }

  .evd-details {
    //display: flex;
    margin-top: 10px;
    .evd-detail-label{
      &:after {
        content: "";
        width: 17px;
        height: 17px;
        background-image: url("../../../assets/images/tick-icon.png");
        background-size: 17px 17px;
        position: absolute;
        margin-top: 10px;
        margin-left: 10px;
        transform: translateY(-50%);
      }
    }

    .common-span {
      position: relative;
      font-size: 16px;
      padding-left: 30px;
      margin-right: 15px;

      &:before {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        background-image: url("../../../assets/images/call.png");
        background-size: 25px 25px;
        left: 0;
      }
    }

    .email {
      color: $link-color;
      text-decoration: underline;

      &:before {
        background-image: url("../../../assets/images/email.png");
      }
    }
    @include break-point(mobile-view) {
      display: block;
      .email,
      .contact {
        margin-bottom: 15px;
      }
    }
  }
}

.firm-detail {
  @extend .common-left-right-padding;

  .title {
    @extend .common-heading;
  }

  .firm-detail-list {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 7.5px 24.5px 0 rgba(230, 215, 238, .53);
    display: flex;
    margin-bottom: 40px;
    padding: 24px 20px 20px;
    @include break-point(tablet-view){
      overflow-x: auto;
    }
    @include break-point(mobile-view) {
      overflow-x: auto;
    }
  }

  .firm-detail-wrapper {
    align-items: center;
    display: inline-flex;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      .firm-detail-right-block {
        .firm-detail-value {
          text-transform: uppercase;
        }
      }
    }

    &:nth-child(2) {
      .firm-detail-right-block {
        .firm-detail-value {
          text-transform: uppercase;
        }
      }
    }
    @include break-point(tablet-view){
      margin-right: 39px;
      display: block;
      white-space: nowrap;
      &:last-child{
        padding-right: 15px;
      }
    }
    @include break-point(mobile-view) {
      margin-right: 39px;
      display: block;
      white-space: nowrap;
    }
  }

  .default-icon {
    background-size: contain;
    background-repeat: no-repeat;
    height: 25px;
    margin-right: 12px;
    width: 30px;

    &.pan {
      background-image: url("../../../assets/images/pan.png");
    }

    &.gstin {
      background-image: url("../../../assets/images/gst.png");
    }

    &.established {
      background-image: url("../../../assets/images/establish.svg");
      height: 26px;
      width: 25px;
    }

    &.firm-type {
      background-image: url("../../../assets/images/firm-icon.png");
      height: 25px;
      width: 25px;
    }

    &.shop-area {
      background-image: url("../../../assets/images/shop-area.svg");
      height: 25px;
      width: 25px;
    }

    &.shop-ownership {
      background-image: url("../../../assets/images/shop-ownership.png");
      height: 26px;
      width: 25px;
    }
  }

  .firm-detail-right-block {
    line-height: 1;

    .firm-detail-label {
      color: $light-font;
      font-size: 14px;
    }

    .firm-detail-value {
      font-family: "VoltePlay_Medium", sans-serif;
      font-size: 16px;
    }
  }
}

.business-detail {
  @extend .common-left-right-padding;
  margin-bottom: 40px;

  .title {
    @extend .common-heading;
  }

  .business-detail-wrapper {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 7.5px 24.5px 0 rgba(230, 215, 238, .53);
    padding-right: 10px;
    padding-left: 10px;
  }

  .business-detail-basic-info {
    display: flex;
    margin-bottom: 5px;
    padding-top: 20px;
    padding-right: 16px;
    padding-left: 16px;

    @include break-point(mobile-view){
      padding-top: 10px;
      padding-right: 0;
      padding-left: 0;
      display: block;
    }
  }

  .basic-info-wrapper {
    margin-bottom: 20px;
    padding-right: 10px;
    width: 20%;

    .label {
      color: $light-font;
      font-size: 14px;
    }

    .value {
      font-size: 16px;
      font-family: "VoltePlay_Medium", sans-serif;
    }

    &:first-child {
      .value {
        max-width: 100px;
        word-break: break-all;
      }
    }
    @include break-point(mobile-view){
      width: 50%;
    }
  }

  .business-detail-table {
    padding-bottom: 10px;

    .current-business-association {
      border: solid 1px #decfd8;
      border-bottom: 0;
      color: #7e5a70;
      font-size: 14px;
      margin: 0;
      padding: 10px 15px;
    }


    .table-wrapper-scrollable {
      border: solid 1px #decfd8;
      overflow-x: auto;


      table {
        width: 100%;
        white-space: nowrap;
      }

      thead {
        th {
          background-color: #fcf1f7;
          color: #7e5a70;
          font-size: 16px;
          font-family: "VoltePlay_Medium", sans-serif;
          font-weight: normal;
          padding: 12px 16px;
          border: solid 1px #decfd8;
          border-top: 0;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
      tbody {
        td {
          font-size: 16px;
          font-weight: normal;
          padding: 12px 16px;
          border: solid 1px #decfd8;

          &:first-child {
            border-left: 0;
          }
        }

        tr {
          &:last-child {
            td {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.document-warehouse-wrapper {
  @extend .common-left-right-padding;
  display: flex;
  padding-top: 30px;
  position: relative;
  z-index: 999;

  .documents-wrapper {
    width: calc(60% - 30px);
    margin-right: 30px;

    .title {
      @extend .common-heading;
    }

    .documents-list-wrapper {
      background-color: $white;
      border: solid 2px $white;
      border-radius: 5px;
      box-shadow: 0 0 24.5px 0 #f2ebee;
      padding: 20px 12px;

      .document-item-wrapper {
        align-items: center;
        display: inline-flex;
        height: 42px;
        margin-bottom: 5px;
        padding-right: 7px;
        padding-left: 7px;
        width: calc(100%/2 - 5px);

        &:nth-child(odd) {
          margin-right: 10px;
        }

        .doc-type-image {
          width: 19px;
          height: 22px;
          background-image: url("../../../assets/images/doc-icon.png");
          background-repeat: no-repeat;
          background-size: 19px 22px;
          position: relative;
        }

        .doc-name {
          font-size: 16px;
          font-family: "VoltePlay_Medium", sans-serif;
          margin-left: 10px;
        }
        .hover-img-wrapper {
          display: none;
        }

        .download {
          display: none;
        }

        &:hover {
          background-color: #fcf1f7;
          border-radius: 5px;
          position: relative;

          .download {
            background-color: $white;
            border-radius: 50%;
            box-shadow: 0 2.5px 4px 0 rgba(227, 199, 214, .75);
            cursor: pointer;
            display: inline-block;
            height: 25px;
            position: absolute;
            top: 50%;
            right: 10px;
            text-align: center;
            transform: translateY(-50%);
            width: 25px;

            .icon {
              height: 12px;
              vertical-align: bottom;
              width: 12px;
            }
          }

          .tooltip-element {
            display: block;
            right: -170px;
            width: 150px;

            &:after {
              top: 10px;
            }

            @include break-point(mobile-view) {
              top: 50px;
              right: 0;

              &:after {
                border-left: 13px solid transparent;
                border-right: 13px solid transparent;
                border-bottom: 13px solid #4b577a;
                top: -20px;
                right: 10px;
                left: unset;
              }
            }
          }
        }
        @include break-point(mobile-view) {
          width: 100%;
          &:hover {
            .doc-type-image {
              display: none;
            }
          }
        }
      }
    }
  }

  .warehouse-wrapper {
    width: 40%;

    .warehouse-details-wrapper {
      height: calc(100% - 31px);
      border-radius: 5px;
      box-shadow: 0 7.5px 24.5px 0 rgba(230, 215, 238, 0.53);
      background-color: $white;
      position: relative;
      padding: 20px 24px;

      &:after {
        content: "";
        position: absolute;
        width: 107px;
        height: 96px;
        background-image: url("../../../assets/images/warehouse.png");
        background-size: 107px 96px;
        background-repeat: no-repeat;
        right: 10px;
        bottom: 10px;
      }
      @include break-point(mobile-view) {
        padding: 15px;
        &::after {
          width: 90px;
          height: 80px;
          background-size: 90px 80px;
        }
      }
    }

    .title {
      @extend .common-heading;
    }

    .label {
      font-size: 14px;
      color: $light-font;
    }
    .value {
      font-size: 16px;
      font-family: "VoltePlay_Medium", sans-serif;
      margin-bottom: 6px;
      width: 100%;
      word-wrap: break-word;
    }

    .totalArea {
      font-size: 19px;
    }
  }
  @include break-point(mobile-view) {
    display: block;
    .documents-wrapper,
    .warehouse-wrapper {
      width: 100%;
    }
    .documents-wrapper {
      margin-bottom: 15px;
    }
  }
}

.bank-prev-partner-slider-wrapper {
  @extend .common-left-right-padding;
  display: flex;
  margin-right: 7px;

  .bank-details-wrapper {
    width: 100%;

    .tab-header {
      @extend .common-heading;
      color: $light-font;
      position: relative;
      cursor: pointer;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      &.activeTab {
        color: $pink-color;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -5px;
          height: 2px;
          background-color: $pink-color;
        }
      }
    }

    .bank-slider-item-margin {
      padding-right: 10px;
      margin-left: 5px;
    }
  }
  @include break-point(mobile-view) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.slider-wrapper {
  margin-left: 20px;
  max-height: 140px;
  width: 100%;

  &.relationship-wrapper {
    width: calc(100% - 56px);
  }

  .slider-container {
    min-height: 150px;
    padding-right: 10px;
    position: relative;

    .slider-item {
      border-radius: 5px;
      background-image: linear-gradient(145deg,  #73c2fd, #1d9eff);
      min-width: 260px;
      min-height: 140px;
      padding-left: 20px;
      padding-top: 30px;
      position: relative;

      &:before {
        content: '';
        background-color: #1d88e0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        height: 10px;
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        z-index: -1;
      }

      &:after {
        background-color: #fff;
        -webkit-mask-image: url("./../../../assets/images/abstract_swrills.svg");
        mask-image: url("./../../../assets/images/abstract_swrills.svg");
        content: '';
        height: 180px;
        position: absolute;
        width: 140px;
        top: 5px;
        right: 10px;
        z-index: 9;
      }

      &.bank-card {
        .img-circle {
          img {
            margin-top: 1px;
            margin-left: 1px;
            width: 53px;
          }
        }
      }

      &.partner-card {
        background-image: linear-gradient(145deg, #ee5faa, #eb288e);
        min-width: 260px;

        &:before {
          background-color: #ce207b;
        }
      }

      &.relationship-card {
        background-image: linear-gradient(-45deg, #797979, #2f2f2f);

        &:before {
          background-color: #353535;
        }

        &:after {
          top: -25px;
          right: -40px;
        }
      }

      .common-slide {
        font-size: 16px;
        color: $white;
        padding-right: 10px;

        &.name {
          display: inline-block;
          font-size: 19px;
        }
      }

      .label {
        color: $white;
        font-family: VoltePlay_Regular, sans-serif;
        line-height: 1;
        opacity: .9;
      }

      .img-circle {
        background-color: #fff;
        border-radius: 50%;
        height: 55px;
        width: 55px;
        position: absolute;
        right: 15px;
        top: 70px;

        img {
          border-radius: 50%;
          height: auto;
          margin-top: 10px;
          margin-left: 5px;
          width: 45px;
        }

        &:after {
          border: 5px solid rgba(255, 255, 255, .3);
          border-radius: 50%;
          content: '';
          height: 63px;
          position: absolute;
          top: -4px;
          right: -4px;
          width: 63px;
        }
      }
    }

    &.relationship-container {
      margin-right: 20px;
    }
  }

  .slick-slider {

    .slick-prev {
      background: #faf9f9;
      top: 70px;
      left: -30px;
    }

    .slick-next {
      background: #faf9f9;
      top: 70px;
      right: -30px;
    }

    .slick-list {
      margin: 0 -20px;

      .slick-slide {
        margin-right: 0!important;
        margin-left: 0!important;
      }
    }
  }
  @include break-point(mobile-view) {
    margin-left: 0;
    .slick-slider {
      .slick-prev {
        left: -10px;
      }
      .slick-next {
        right: -10px;
      }
      .slick-list {
        margin: 0;
      }
    }
    .slider-container {
      padding-right: 13px;
    }

    &.relationship-wrapper {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.section-title {
  color: $pink-color;
  font-family: VoltePlay_Medium, sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  @include break-point(mobile-view) {
    margin-top: 15px;
    margin-bottom: 10px;
    padding: 0 15px;
  }
}

// Store View CSS
.accordion-container {
  padding: 0 26px;
}
.accordion {
  background-color: #fcf1f7;
  border: 1px solid #ccc !important;
  font-family: SkyText-Regular;
  color: #7e5a70;
  cursor: pointer;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  outline: none;
  transition: 0.4s;
  text-transform: uppercase;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #fcf1f7;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  padding: 0;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  margin-bottom: 5px;

  table, td, th {
    border: 1px solid #ccc;
    padding: 7px 10px;
  }

  td, th {
    width: 50%;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
}

.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 10px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}
