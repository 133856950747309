@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
#loader-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgb(249, 249, 249);
  opacity: 0.95;
  .taskClass{
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%) ;
  }
}
.loader {
  display: block;
  width: 75px;
  height: 75px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #ff9000;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: fixed;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #ff0000;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loader:after {
  content: "";
  position: absolute;
  top: 11px;
  left: 11px;
  right: 11px;
  bottom: 11px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #0a00b2;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
