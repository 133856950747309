@import "../../../../../assets/stylesheet/global/varibale";

$onHoverPadding: 40;

.task-list-wrapper {
  padding-left: 28px;
  padding-right: 32px;
  margin-bottom: 26px;
  min-height: 295px;
}

.task-row-wrapper {
  border-bottom: solid 1px #383a531a;
  display: flex;
  padding-top: 30px;
  padding-bottom: 24px;
  position: relative;

  &:hover {
    background-color: $white;
    cursor: pointer;
    box-shadow: 0px 9.5px 28.5px 0 #f7f2f7;
    border: solid 2px #eadeda;
    border-left: 0;
    border-right: 0;
    margin-top: -1px;

    &:before {
      content: "";
      position: absolute;
      width: #{$onHoverPadding}px;
      height: calc(100% + 4px);
      background-color: $white;
      top: -2px;
      left: -#{$onHoverPadding}px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: solid 2px #eadeda;
      border-right: 0;
    }

    &:after {
      content: "";
      position: absolute;
      width: #{$onHoverPadding}px;
      height: calc(100% + 4px);
      background-color: $white;
      top: -2px;
      right: -#{$onHoverPadding}px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: solid 2px #eadeda;
      border-left: 0;
    }
  }

  .task-reverted {
    position: absolute;
    font-family: "VoltePlay_Medium", sans-serif;
    opacity: 0.7;
    border-radius: 5px;
    box-shadow: 0px 3.5px 6.5px 0 rgba(248, 200, 42, 0.57);
    background-color: #fdd24a;
    top: 10px;
    left: 0px;
    font-size: 11px;
    padding: 2px 6px 2px 18px;
    color: $black;

    &:before {
      content: "";
      width: 9px;
      height: 10px;
      background-image: url("../../../../../assets/images/bell-icon.svg");
      background-size: 9px 10px;
      position: absolute;
      left: 4px;
      top: 5px;
    }
  }

  .task-number {
    position: relative;
    font-size: 27px;
    width: 10%;
    color: rgba($color: $font-default-color, $alpha: 0.5);
  }

  .hash-symbol {
    font-size: 18px;
  }

  .task-id-wrapper {
    width: 20%;
  }

  .request-id {
    display: block;
    font-family: "VoltePlay_Medium", sans-serif;
    font-size: 19px;
    color: $pink-color;
  }

  .created-on {
    font-size: 14px;
  }

  .task-partner-wrapper {
    width: 40%;
  }

  .task-partner-name {
    display: block;
    font-family: "VoltePlay_Medium", sans-serif;
    font-size: 19px;
    overflow-wrap: break-word;
  }

  .previousPartnerLogo {
    position: relative;

    &:after {
      content: "";
      width: 17px;
      height: 17px;
      background-image: url("../../../../../assets/images/tick-icon.png");
      background-size: 17px 17px;
      position: absolute;
      top: 4px;
      margin-left: 10px;
    }
  }

  .task-partner-location {
    font-size: 14px;
    width: 100%;
    word-wrap: break-word;
  }

  .task-assigned-wrapper {
    text-align: right;
    width: 30%;
  }

  .task-assigned-on {
    font-size: 14px;
    display: block;
  }

  .task-assigned-days {
    font-family: "VoltePlay_Medium", sans-serif;
    color: $pink-color;
  }

  .task-assignee-name {
    font-size: 16px;
    position: relative;
    width: 90%;
    overflow-wrap: break-word;

    &::before {
      content: "";
      position: absolute;
      width: 11px;
      height: 13px;
      background-image: url("../../../../../assets/images/assigned-to-user-icon.svg");
      background-size: 11px 13px;
      top: 4px;
      left: -16px;
    }
  }
}

@media screen and (max-width: 767px) {
  .task-list-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .task-row-wrapper {
    border-radius: 6px;
    box-shadow: 0px 11px 35px 0 rgba(230, 215, 238, 0.53);
    background-color: $white;
    flex-direction: column;
    padding: 15px 11px 21px 18px;
    margin-bottom: 8px;

    &:hover {
      border: 0;
      box-shadow: 0px 11px 35px 0 rgba(230, 215, 238, 0.53);

      &:before {
        content: none;
      }
      &:after {
        content: none;
      }
    }

    .common-div {
      width: 100%;
    }

    .task-id-wrapper {
      margin-bottom: 5px;
    }

    .task-partner-wrapper {
      margin-bottom: 7px;
    }

    .task-reverted {
      bottom: 12px;
      top: auto;
      right: 11px;
      left: auto;
    }

    .task-number {
      position: absolute;
      right: 12px;
      text-align: right;
      max-width: 100px;
    }

    .task-assigned-wrapper {
      text-align: left;
    }

    .task-assignee-name {
      padding-left: 16px;

      &:before {
        left: 0;
      }
    }
  }
}
