@import "./../global/varibale";
@import "./../global/mixin";

.radio-field {
  cursor: pointer;
  display: inline-block;
  padding-left: 28px;
  margin-right: 40px;
  position: relative;
  user-select: none;

  .radio-element {
    cursor: pointer;
    height: 24px;
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 1px;
    width: 24px;
    z-index: 1;

    &:checked {
      ~ .select-radio {
        background-color: var(--white);
        border-color: var(--nykaa-pink);

        &:after {
          display: block;
        }
      }
    }
    &:disabled {
          ~.select-radio {
            background-color: $grey;
            cursor: not-allowed;
            &:after{
              background: $grey;
            }
          }
     }
  }

  .select-radio {
    &:after {
      background-image: linear-gradient(to top, #46adfb, #1d9eff);
      box-shadow: 0 2.5px 4px 0 rgba(156, 202, 252, .6);
      transform: translate(-50%, -50%);
      border-radius: 50%;
      height: 18px;
      top: 50%;
      left: 50%;
      width: 18px;
    }
  }

  .radio-text {
    cursor: pointer;
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding-left: 7px;

    @include break-point(mobile-view) {
      font-size: 14px;
    }
  }

  &.image-type {
    height: 105px;
    margin-right: 50px;
    padding-left: 0;
    width: 80px;

    .radio-element {
      height: 105px;
      top: 0;
      left: 0;
      width: 115px;
      z-index: 9;

      &:checked {
        ~ .select-radio {
          background-image: linear-gradient(-75deg, #e40039, #e5007d);
          border-color: transparent;

          &:after {
            display: block;
          }
        }

        ~ .radio-text {
          color: $white;
          font-size: 16px;
        }

        ~ .radio-icon {
            svg {
              path {
                fill: $white;
              }

              defs {
                #owned {
                  feFlood {
                    flood-color: $white;
                  }
                }
              }
            }
        }
      }
    }

    .select-radio {
      background-image: $white;
      box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
      border-radius: 5px;
      height: 105px;
      opacity: 0.8;
      padding: 15px;
      width: 115px;

      &:after {
        content: none;
      }
    }

    .radio-icon {
      position: absolute;
      top: 15px;
      text-align: center;
      width: 115px;
      z-index: 8;
    }

    .radio-text {
      color: $font-default-color;
      position: absolute;
      z-index: 1;
      bottom: 0;
      width: 115px;
      text-align: center;
      padding-left: 0;
    }

    @include break-point(mobile-view) {
      margin-bottom: 10px;
    }
  }
}

.select-radio {
  background-color: $white;
  box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, 0.45);
  border-radius: 50%;
  height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;

  &:after {
    content: '';
    display: none;
    position: absolute;
  }
}