@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.task-list-wrapper-on-hover {
  position: relative;
}

/* .selected-role-mobile {
  display: none;
} */

.task-by-role-list-wrapper {
  border-bottom: 1px dotted #b76f68;
  padding-bottom: 10px;
  padding-left: 5px;
  margin-top: 30px;
  margin-right: 30px;

  .task-by-role-item {
    cursor: pointer;
    font-family: VoltePlay_Medium, sans-serif;
    margin-top: 15px;
    margin-bottom: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    display: flex;
    align-items: center;

    .task-by-role {
      font-size: 16px;
      margin-right: 10px;
    }

    .task-count {
      font-size: 14px;
      color: $light-font;
      opacity: .7;
      vertical-align: text-bottom;
    }

    &.activeRole {
      .task-by-role {
        color: $pink-color;
      }

      .task-count {
        opacity: 1;
      }

      &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 7px;
        background-image: url("../../../assets/images/arrow.svg");
        background-size: 16px 7px;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }
    }

    &.status-icon {
      .task-by-role {
        padding-left: 15px;
        margin-left: 7px;
      }

      &:before {
        content: '';
        position: absolute;
        // top: 7px;
        // left: -5px;
      }

      &.approved_img {
        &:before {
          background: url("./../../../assets/images/approved.png") no-repeat;
          background-size: 15px 11px;
          height: 11px;
          width: 15px;
        }
      }

      &.rejected_img {
        &:before {
          background: url("./../../../assets/images/reject.svg") no-repeat;
          background-size: 12px 12px;
          height: 12px;
          width: 12px;
        }
      }

      &.on-hold-img {
        &:before {
          background: url("./../../../assets/images/hold.svg") no-repeat;
          background-size: 12px 12px;
          height: 12px;
          width: 12px;
        }
      }
    }
  }

  &.status-list-wrapper {
    text-transform: capitalize;
  }
}

@media screen and (max-width: 991px) {
  .selected-role-mobile {
//     display: block;
    border-radius: 4px;
    border: solid 2px #f2f1f1;
    background-color: $white;
    position: relative;
    padding-top: 11px;
    padding-left: 12px;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .task-by-role-mobile {
      font-family: "VoltePlay_Medium", sans-serif;
      color: $pink-color;
      font-size: 16px;
      margin-right: 5px;
    }

    .task-count-mobile {
      font-size: 14px;
      color: $font-default-color;
      vertical-align: text-bottom;
    }

    &:after {
      content: "";
      width: 17px;
      height: 12px;
      position: absolute;
      right: 12px;
      background: url("../../../assets/images/task-by-role-dropdown-icon.svg");
      background-size: 17px 12px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .task-by-role-list-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    background-color: $white;
    border-radius: 4px;
    top: -20px;
    box-shadow: 0 10px 24.5px 0 #ccaba9;
    z-index: 2;

    @include break-point(mobile-view) {
      &.status-list-wrapper {
        z-index: 1;
      }
    }

    .task-by-role-item {
      padding-top: 11px;
      padding-left: 12px;
      padding-bottom: 10px;

      @include break-point(mobile-view) {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 5px;
        margin-bottom:5px;
      }

      &:last-child {
        border: 0;
      }

      &.activeRole {
        &:after {
          content: none;
        }
      }
    }
  }
}
