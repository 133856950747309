@import "./../../assets/stylesheet/global/varibale";
@import "./../../assets/stylesheet/global/mixin";

.bg-custom {
  background: url("./../../assets/images/bg_inner.jpg") no-repeat fixed;
  width: 100%;
  height: 100%;

  .user-view-container {
    position: relative;
    z-index: 1;

    &:after {
      background-color: #b59f9f;
      -webkit-mask-image: url("./../../assets/images/abstract_swrills.svg");
      mask-image: url("./../../assets/images/abstract_swrills.svg");
      content: '';
      height: 550px;
      position: absolute;
      width: 430px;
      top: 100px;
      left: 300px;
      z-index: 9;
    }

    @include break-point(mobile-view) {
      &:after {
        content: none;
      }
    }
  }

  .inner-bg-layer {
    background: #f9f5f5;
    height: 100%;
    opacity: .9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .left-sidebar-tab {
    .list {
      &.not-allowed{
        pointer-events: none;
      }
      align-items: center;
      display: inline-flex;
      height: 75px;
      margin-bottom: 30px;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
      position: relative;
      width: 310px;
      opacity: .5;
      // pointer-events: none;

      @include break-point(mobile-view) {
        background-color: $white;
        opacity: 1;

        .down-icon {
          display: block;
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);

          svg {
            path {
              fill: $white;
            }
          }
        }
      }

      &.active {
        background-image: linear-gradient(to left, #46adfb, #1d9eff);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: 0 3.5px 9px 0 rgba(201, 159, 155, 0.44);
        cursor: pointer;
        opacity: 1;
        pointer-events: unset;
        position: relative;

        @include break-point(mobile-view) {
          background-image: linear-gradient(to left, #46adfb, #1d9eff);
          border-radius: 0;
          box-shadow: none;
        }

        .number {
          color: rgba(255, 255, 255, .2);
          text-shadow: none;

          @include break-point(mobile-view) {
            color: rgba(255, 255, 255, .8);
          }
        }

        .value {
          color: $white;

          .sub-title {
            color: $white;
            opacity: .88;
          }
        }

        &:after {
          content: '';
          width: 0;
          height: 0;
          border-top: 37.5px solid transparent;
          border-bottom: 37.5px solid transparent;
          border-left: 37.5px solid #46adfb;
          position: absolute;
          top: 0;
          right: -37px;

          @include break-point(mobile-view) {
            content: none;
          }
        }
      }

      .number {
        color: $white;
        font-size: 50px;
        padding-right: 10px;
        text-shadow: 0 2.5px 9px rgba(236, 203, 203, 0.42);

        @include break-point(mobile-view) {
          font-size: 21px;
          font-weight: 600;
          text-shadow: none;
          margin-top: -12px;
          padding-right: 5px;
        }
      }

      .value {
        .title {
          font-family: VoltePlay_Medium, sans-serif;
          font-size: 20px;

          @include break-point(mobile-view) {
            font-size: 16px;
            line-height: 1;
          }
        }

        .sub-title {
          color: $light-font;
          font-size: 13px;
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      @include break-point(mobile-view) {
        border-bottom: 1px solid $grey;
        border-radius: 0;
        height: 55px;
        margin-bottom: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        width: 100%;

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    @include break-point(mobile-view) {
      &.responsive-view {
        box-shadow: 0 10px 24.5px 0 #ccaba9;
        display: block;
        position: absolute;
        top: 167px;
        left: 0;
        z-index: 99;

        .list {
          .number {
            color: $font-default-color;
          }

          &.active {
            display: none;

            .number {
              color: $white;
            }
          }

          &:first-child {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px
          }

          &:last-child {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding-top: 30px;
            padding-bottom: 30px;
          }
        }
      }

      &.default {
        .list {
          background-image: linear-gradient(to left, #46adfb, #1d9eff);
          border-radius: 5px;

          .number {
            color: $white;
          }

          .value {
            color: $white;
          }

          .sub-title {
            color: rgba($white, 0.8);
          }
        }
      }

      &.open-dropdown {
        .down-icon {
          transform: rotate(180deg) translateY(15px);
          transition: all 0.2s ease-in-out;
        }
      }
    }

    @include break-point(tablet-view) {
      .list {
        margin-bottom: 10px;
        padding: 8px;
        width: 50%;

        .number {
          font-size: 30px;
          padding-right: 5px;
        }

        .value {
          .title {
            font-size: 16px;
          }
        }

        &.active {
          border-radius: 5px;

          &:after {
            content: none;
          }
        }
      }
    }
  }

  .form-view {
    background-color: rgba(255, 255, 255, .75);
    border: solid 2px $white;
    border-radius: 5px;
    box-shadow: 0 0 24.5px 0 #e0cdcc;
    min-height: 500px;
    margin-bottom: 120px;
    margin-left: 50px;
    padding: 30px 50px;

    @include break-point(mobile-view) {
      background: transparent;
      box-shadow: none;
      border: 0;
      margin-bottom: 40px;
      margin-left: 0;
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;
    }

    .title-1 {
      display: inline-flex;
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 24px;

      @include break-point(mobile-view) {
        font-size: 18px;
        padding-top: 30px;
      }
    }

    .description {
      color: $light-font;
    }

    .title-2 {
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 16px;
      text-transform: uppercase;
    }

    @include break-point(tablet-view) {
      margin-left: 0;
    }
  }
}

.form-control-group {
  position: relative;
  display: flex;

  @include break-point(mobile-view) {
    height: 120px;

    .validate {
      &.invalid-text {
        padding-top: 85px;
      }
    }
  }

  .form-element {
    width: 100%;

    .input-group-element {
      padding-top: 20px;
      padding-bottom: 10px;

      .input-element {
        padding-top: 6px;
        padding-left: 225px;

        @include break-point(mobile-view) {
          height: 36px;
          padding-left: 15px !important;
          position: absolute;
          bottom: -85px;
        }
      }
    }

    &.w-100 {
      .input-element {
        padding-left: 10px;
      }
    }
  }

  .radio-group {
    background: #f9f8f8;
    border-radius: 5px;
    height: 42px;
    line-height: 42px;
    padding-left: 8px;
    position: absolute;
    top: 23px;
    left: 3px;

    .select-radio {
      top: 7px;
    }

    @include break-point(mobile-view) {
      background-color: transparent;
      border: 2px solid #fff;
      left: 0;
    }
  }

  .select-input-form {
    position: relative;

    .input-group-element {
      padding-bottom: 0;
    }

    select {
      -webkit-appearance: none;
    }

    &:after {
      background: url('./../../assets/images/dropdown-arrow.svg');
      content: '';
      height: 11px;
      position: absolute;
      top: 28px;
      left: 37.5%;
      width: 11px;

      @include break-point(mobile-view) {
        top: 25px;
        right: 3px;
        left: unset;
      }
    }

    &.without-btn-control {
      input.input-element {
        padding-left: 220px !important;

        @include break-point(mobile-view) {
          padding-left: 15px !important;
        }

        @include break-point(tablet-view) {
          padding-left: 260px !important;
        }
      }
    }
  }

  .select-input {
    padding-top: 0 !important;

    &.show-placeholder {
      .input-element {
        &::placeholder {
          color: $light-font;
          font-family: VoltePlay_Regular, sans-serif;
          z-index: 999;
        }

        &:disabled {
          &::placeholder {
            color: transparent;
          }
        }
      }
    }

    .input-element {
      padding-left: 190px !important;
    }

    select.input-element {
      background-color: #f9f8f8;
      height: 40px;
      margin-left: 2.5px;
      padding-left: 15px !important;
      position: absolute;
      top: 3px;
      left: 0;
      width: 40%;

      @include break-point(mobile-view) {
        margin-left: 0;
        width: 100%;
      }
    }

    @include break-point(tablet-view) {
      .input-element {
        padding-left: 240px !important;
      }
    }
  }

  &.disabled {
    .input-element {
      @include disabled-element;
      box-shadow: none;
    }

    select.input-element {
      @include disabled-element;
      border: 1px solid #ddd;
    }

    .add-btn {
      @include disabled-element;
      color: $light-font;

      i {
        svg {
          path {
            fill: $light-font;
          }
        }
      }
    }
  }
}

.application-summary-details {
  background-color: #612573;
  border-radius: 5px;
  box-shadow: 0 2.5px 4px 0 rgba(90, 43, 111, .43);
  font-family: VoltePlay_Medium, sans-serif;
  margin-left: 30px;
  padding-top: 30px;

  &:after {
    background-color: #f3f3f3;
    -webkit-mask-image: url("./../../assets/images/abstract_swrills.svg");
    mask-image: url("./../../assets/images/abstract_swrills.svg");
    content: '';
    height: 175px;
    position: absolute;
    right: -25px;
    top: -15px;
    width: 140px;

  }

  .title {
    color: rgba(255, 212, 235, 0.97);
    text-transform: uppercase;
  }

  .value {
    color: $white;
    font-size: 19px;
    overflow-wrap: break-word;
  }

  .lh-1 {
    line-height: 1.1;
  }

  .status {
    background-color: #5a236b;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    line-height: 1.1;
    padding-top: 15px;
    padding-bottom: 15px;

    .value {
      color: #ffed00;
    }
  }
}

.clearfix {
  clear: both;
}

.add-btn-right {
  float: right;
  font-size: 12px;
  height: 30px;
  margin-bottom: 20px;
  padding: 0;
  width: 84px;

  @include break-point(mobile-view) {
    margin-top: 10px;
  }

  .action-icon {
    vertical-align: middle;
  }

  &.remove {
    background-color: #e50078;
  }

  span {
    margin-left: 8px;
  }
}

.align-center {
  align-self: center;
}

.upload-document-section {
  margin-bottom: 30px;

  .table {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px dashed $grey;
    border-bottom-width: 0;

    &.doc-approved {
        position: relative;
        background-color: #d9fbe0;
        pointer-events: none;
        &:after {
          background: url("./../../assets/images/doc_approved.svg") no-repeat 0 0;
            background-size: cover;
            content: '';
            height: 35px;
            position: absolute;
            top: 50%;
            left: -40px;
            width: 35px;
            transform: translateY(-50%);
          }
    }

    &.doc-error {
      position: relative;
      background-color: #fbdbdb;

      &:after {
        background: url("./../../assets/images/alert-large.png") no-repeat 0 0;
        background-size: cover;
        content: '';
        height: 25px;
        position: absolute;
        top: 50%;
        left: -40px;
        width: 29px;
        transform: translateY(-50%);
      }

      .radio-group {
        background-color: #f9f8f8;
      }
    }

    .cell {
      border-right: 1px dashed $grey;
      flex: 1;
      padding: 25px 10px 20px 15px;

      .cell-heading {
        font-family: VoltePlay_Medium, sans-serif;
        font-size: 16px;
        padding-bottom: 5px;
      }

      .doc-list {
        display: flex;

        li {
          padding: 4px;
          background: white;
          margin-right: 8px;
          color: #3064e8;
        }

        .delete-doc {
          color: #e93292;
          cursor: pointer;
        }
      }
    }

    .upload-doc-right-section {
      flex: 0.4;
      text-align: center;

      .upload-button {
        position: absolute;
      }

      .button {
        font-size: 14px;
        width: 120px;
      }
    }

    &:last-child {
      border-bottom-width: 1px;
    }
  }

  .upload-button {
    .input-hidden {
      visibility: hidden;
      width: 0;
    }
  }

  @include break-point(mobile-view) {
    .table {
      align-items: flex-start;
      flex-direction: column;

      .cell {
        border-right-width: 0;
        padding-top: 30px;
      }

      .upload-doc-right-section {
        margin-bottom: 10px;
        margin-left: 15px;
        text-align: left;
        width: 100%;

        .button {
          margin-left: 0;
        }
      }

      &.doc-error {
        &:after {
          height: 17px;
          top: 7px;
          right: 7px;
          left: unset;
          width: 19px;
          transform: unset;
        }
      }

      &.doc-approved {
        &:after {
          height: 20px;
          top: 7px;
          right: 7px;
          left: unset;
          width: 20px;
          transform: unset;
        }
      }
    }
  }
}

.application-tab-view {
  display: none;
  position: relative;
  width: 100%;

  @include break-point(mobile-view) {
    display: block;

    .left-sidebar-tab {
      display: flex;

      .dropdown-left-sidebar {
        display: none;
      }

      li {
        margin-bottom: 0;

        &.active {
          display: flex;

          &:before {
            content: '>';
            font-weight: 600;
            font-size: 18px;
            line-height: 1;
            transform: rotate(90deg);
            position: absolute;
            right: 10px;
          }

          &:hover {
            + .dropdown-left-sidebar {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 50px;
              left: 0;
              width: 100%;
              z-index: 999;
            }
          }
        }
      }
    }
  }
}

.hard-copy-list   {
  margin-bottom: 40px;
  min-height: 75px;
  padding-left: 100px;
  position: relative;

  &::before {
    background-color: $white;
    border-radius: 50%;
    box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
    content: '';
    height: 75.5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 75.5px;

    @include break-point(mobile-view) {
      height: 60px;
      width: 60px;
    }
  }

  &:after {
    position: absolute;
    top: 12px;
    left: 15px;

    @include break-point(mobile-view) {
      transform: scale(.8);
    }
  }

  &:first-child {
    &::after {
      content: url('../../assets/images/couriered.png');

      @include break-point(mobile-view) {
        top: 8px;
        left: 8px;
      }
    }
  }

  &:nth-child(2) {
    &::after {
      content: url('../../assets/images/location.png');
      top: 20px;
      left: 22px;

      @include break-point(mobile-view) {
        top: 12px;
        left: 15px;
      }
    }
  }

  &:nth-child(3) {
    &::after {
      content: url('../../assets/images/track.png');
      top: 22px;

      @include break-point(mobile-view) {
        top: 14px;
        left: 6px;
      }
    }
  }
}

.hardCopy-details-wrapper {
  .title {
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 16px;
    padding-bottom: 8px;
    text-transform: uppercase;
  }

  .sub-title {
    color: $light-font;
    font-family: VoltePlay_Medium, sans-serif;
    padding-bottom: 2px;

    &:nth-last-of-type(2) {
      display: none;
    }
  }

  &:nth-child(2) {
    margin-bottom: 60px;

    .description-d {
      color: $font-default-color;
    }
  }

  .description-d {
    color: $light-font;
    margin-bottom: 0;

    .value {
      color: $font-default-color;
      font-family: VoltePlay_Medium, sans-serif;
    }
  }

  @include break-point(mobile-view) {
    padding-left: 90px;

    .upload-submit-btn {
      margin-bottom: 0;
      min-height: unset;
      width: 100%;
      letter-spacing: 0.5px;
    }
  }

  .partner-tracking-details {
    padding-left: 100px;
  }
}

.form-element {
  &.hardcopy-upload {
    margin-bottom: 40px;
    position: relative;

    +.sub-title {
      display: none;
    }

    @include break-point(mobile-view) {
      margin-bottom: 25px;
    }

    &.error {
      .input-element {
        border: 0;
      }

      ~ .upload-doc-list {
        border: 1px solid $red;
        min-height: 40px;
      }
    }

    .input-group-element {
      display: flex;
      justify-content: flex-end;

      @include break-point(mobile-view) {
        justify-content: flex-start;
        margin-top: 20px;
      }

      .input-element {
        box-shadow: none;
        color: transparent;
        margin-top: 20px;
        height: 42px;
        max-width: 80px;

        &:disabled {
          background-color: transparent;

          &:before {
            background-color: #f3e9e9;
            color: #6e655c;
          }
        }

        @include break-point(mobile-view) {
          max-width: 100%;
          padding-right: 0;
        }

        &::-webkit-file-upload-button {
          visibility: hidden;
        }

        &:active {
          outline: 0;

          &:before {
            background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
          }
        }

        &:focus {
          border-width: 0;
        }
      }

      .floating-label {
        font-size: 14px;
        color: $light-font;
        top: 0;
        left: 0;
        text-transform: unset;

        @include break-point(mobile-view) {
          top: -20px;
        }

        &:before {
          background-color: $link-color;
          border-width: 0;
          border-radius: 5px;
          content: 'Upload';
          color: $white;
          cursor: pointer;
          display: inline-block;
          height: 42px;
          line-height: 42px;
          outline: none;
          position: absolute;
          top: 19px;
          right: -189px;
          text-align: center;
          width: 82px;

          @include break-point(mobile-view) {
            height: 42px;
            width: 100%;
          }
        }

      }
    }

    ~ .upload-doc-list {
      background-color: $white;
      border-radius: 5px;
      box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
      position: absolute;
      top: 157px;
      display: inline-block;
      height: auto;
      line-height: 40px;
      min-height: 42px;
      padding: 10px 15px;
      width: calc(100% - 190px);

      li {
        font-family: VoltePlay_Medium, sans-serif;
        word-break: break-word;
        line-height: 1.5;


        .delete-icon {
          cursor: pointer;
          vertical-align: middle;
        }
      }

      @include break-point(mobile-view) {
        height: auto;
        margin-top: -20px;
        padding: 0;
        position: static;
        width: 100%;

        li {
          padding: 10px;
          overflow-wrap: break-word;
        }
      }
    }
  }

  &.track-input {
    width: 50%;
    height: 80px;

    .input-group-element {
      padding-top: 20px;

      .input-element {
        padding-top: 10px;
        padding-left: 15px;
      }

      .floating-label {
        color: $light-font;
        font-size: 14px;
        top: 0;
        left: 0;
        text-transform: unset;
      }
    }

    @include break-point(mobile-view) {
      width: 100%;
    }
  }
}

.uploaded-doc-list {
  margin-bottom: 0;

  li {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, .45);
    color: $link-color;
    display: inline-block;
    font-family: VoltePlay_Medium, sans-serif;
    margin-right: 8px;
    margin-bottom: 10px;
    padding: 6px 10px;

    &:last-child {
      margin-right: 0;
    }

    > span {
      padding-right: 5px;
      color: $pink-color;
    }

    .delete-icon {
      cursor: pointer;
      line-height: 0;
      vertical-align: middle;
    }
  }
}

.outer-heading {
  padding-top: 20px;
  padding-bottom: 20px;

  &:first-child {
    padding-top: 0;
  }

  &.docDetailsHeader {
    padding-bottom: 0;

    hr.no-space {
      display: none;
    }
  }
}

.evaluation-form {
  .pb20 {
    .title-2 {
      @include break-point(mobile-view) {
        padding-top: 10px;
      }
    }
  }
}

.bank-partner-form {
  display: flex;

  @include break-point(mobile-view) {
    display: block;
    clear: both;

    .add-btn {
      float: right;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }
}

.bankDetailsHeading {
  @include break-point(mobile-view) {
    padding-top: 50px;
  }
}

.docDetailsHeader {
  @include break-point(mobile-view) {
    padding-top: 50px;
  }
}

.partner-form-container {
  @include break-point(mobile-view) {
    overflow-x: hidden;
  }

  .responsive-order {
    &:nth-of-type(0) {
      order: 1;
    }

    @include break-point(mobile-view) {
      &:nth-of-type(1) {
        order: 1;
      }
      &:nth-of-type(2) {
        order: 3;
      }
      &:nth-of-type(3) {
        order: 2;

        .application-summary-details {
          margin-top: 10px;
          margin-left: 0;

          .value {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.custom-select.disabled > div {
  background-color: #f3e9e9;
  box-shadow: none;
  color: #6e655c;
  pointer-events: none;
  border-style: none;
}

.application-form-disabled {
  .custom-select > div {
    background-color: #f3e9e9;
    box-shadow: none;
    color: #6e655c;
    pointer-events: none;
    border-style: none;
  }
  .button {
    background-color: $grey;
    border-color: $grey;
    color: $white;
    pointer-events: none;

    &.remove {
      background-color: $grey;
      border-color: $grey;
      color: $white;
    }
  }
}

.required{
  color: #ff0000;
  margin-left: 2px;
}

.required-symbol{
  &::after{
    content:' *';
    color: #ff0000;
    position: absolute;
    right: -8px;
    top: 0;
  }
}

.top-space-removed {
  margin-top: -20px!important;
}

.existingRelationshipHeader16 {
  padding-top: 10px;
}

.data-list-control{
  .input-element {
    padding-right: 5px;
  }
}

.justify-text {
  text-align: justify;
}

.no-select-bar.form-element.select-control:after {
  background: none;
}

.custom-select {
  font-family: VoltePlay_Medium, sans-serif;
  font-size: 16px;
  > div {
    //border: solid 1px #1d9eff;
    box-shadow: 0 2.5px 5px 0 rgba(230, 215, 238, 0.45);
  }
  > div:hover {
    border: solid 1px #1d9eff !important;
  }
  input {
    height: 35px;
    @include break-point(mobile-view) {
      height: 20px;
      /*padding-left: 15px !important;
      position: absolute;
      bottom: -85px;*/
    }
  }

  &.disabled {
      @include disabled-element;
      box-shadow: none;
  }
}

.m-top-0, .m-top-10, .m-top-20, .m-top-25, .m-top-30{
  .required-symbol::after{
    position: relative;
    right: -2px;
  }
}


@media only screen and  (max-width: 1310px) and (min-width: 992px) {
  .m-top-10{
    margin-top: 10px;
    .label-title {
      top: -10px !important;
    }
  }
  .m-top-20{
    margin-top: 20px;
    .label-title {
      top: -20px !important;
    }
  }
  .m-top-25{
    margin-top: 25px;
    .label-title {
      top: -25px !important;
    }
  }
  .m-top-30{
    margin-top: 30px;
    .label-title {
      top: -30px !important;
    }
  }
}

.m-margin-15{
  margin-right: 15px !important;
}

.m-margin-0{
  margin-right: 0px !important;
}


// @media only screen and  (max-width: 1210px) and (min-width: 992px) {
//   .family-dth{
//     margin-top: 20px;
//     .label-title{
//       top: -20px !important
//     }
//   }
// }

//
//.form-element .input-element:disabled {
//  background-color: #f3e9e9;
//  box-shadow: none;
//  color: #6e655c;
//}
