@import "./../global/varibale";

.checkbox-element {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 13px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    left: 0;
    top: -2px;
    z-index: 99;

    &:checked {
      ~ .checkmark {
        background-color: $link-color;
        border: 0;
        z-index: 9;

        &:after {
          display: block;
        }
      }
    }

    &:disabled {
      +.checkmark {
        background-color: $grey;
        cursor: not-allowed;
      }
    }
  }

  .checkmark {
    &:after {
      left: 7px;
      top: 2px;
      width: 6px;
      height: 12px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &.error {
    .checkmark {
      border-color: $red;
    }

    ~.invalid-text {
      color: $red;
      display: block;
      font-size: 12px;
      padding-left: 30px;
    }
  }
}


.checkmark {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 6px;
  position: absolute;
  top: -1.5px;
  left: 0;
  height: 20px;
  width: 20px;

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.link {
  color: $pink-color;
  cursor: pointer;
}