@import "mixin";
$fixed: "../../fonts/Sky_Text_Font/";
$fixed: "../../fonts/tata_play_font/";

@include font-type(VoltePlay_Light, '#{$fixed}VoltePlay-Light.ttf');
@include font-type(VoltePlay_Regular, '#{$fixed}VoltePlay-Regular.ttf');
@include font-type(VoltePlay_Medium, '#{$fixed}VoltePlay-Medium.ttf');
@include font-type(VoltePlay_SemiBold, '#{$fixed}VoltePlay-SemiBold.ttf');
@include font-type(VoltePlay_Bold, '#{$fixed}VoltePlay-Bold.ttf');
// @include font-type(Sky_Italic, '#{$fixed}Sky_Italic.ttf');
@include font-type(Indian_Rupee, '../../fonts/Indian_Rupee_Font/Indian_Rupee.ttf');