@import "../../../../assets/stylesheet/global/varibale";

.task-list-header-wrapper {
  display: flex;
  justify-content: space-between;
  padding-left: 28px;
  padding-right: 32px;
  margin-bottom: 0;
  margin-top: 32px;

  .pending-req {
    font-size: 16px;
  }

  .pending-count {
    color: $pink-color;
  }
}

.task-header-dropdown {
  display: inline-block;
  margin-left: 20px;
  min-width: 158px;
  vertical-align: top;

  &:not(:last-child) {
    margin-right: 14px;
  }

  .input-element {
    height: 45px;
    padding: 0 14px 0 14px;
    font-size: 17px;
    background-color: #ffffff;
    border: solid 1px $grey;;
    box-shadow: 0 9.5px 28.5px 0 #f7f2f7;
    font-family: "VoltePlay_Regular", sans-serif;

    &:focus {
      borde-color: #1d9eff;
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .task-list-header-wrapper {
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    flex-direction: column;

    .left-heading{
      margin-bottom: 10px;
    }
  }
  .task-header-dropdown{
    min-width: 129px;
    margin-left: 0;
    width: 100%;
    &:not(:last-child) {
      margin-right: 21px;
    }
  }
}

.common-heading {
  font-size: 16px;
  font-family: "VoltePlay_Medium", sans-serif;
  font-weight: normal;
  color: $pink-color;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.tab-header {
  @extend .common-heading;
  color: $light-font;
  position: relative;
  cursor: pointer;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  &.activeTab {
    color: $pink-color;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -5px;
      height: 2px;
      background-color: $pink-color;
    }
  }
}
