@import "../../assets/stylesheet/global/varibale";
@import "../../assets/stylesheet/global/mixin";

.list-detail-wrapper {
  border-radius: 5px;
  box-shadow: 0 0 24.5px 0 #e0cdcc;
  background-color: #fbf7f7;
  border: solid 2px $white;

  .left-section-wrapper {
    padding-bottom: 40px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .col-lg-5 {
      @include break-point(tablet-view) {
        margin-top: 20px;
        margin-left: 25px;
      }
    }
  }

  .right-section-wrapper {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: inset 12px -5.1px 10.5px 0 rgba(214, 213, 213, .2);
    background-color: #f4f0f0;
    padding-top: 20px;
  }

  @include break-point(mobile-view) {
    margin-bottom: 45px;
    overflow-x: hidden;
  }
}
