$m: "margin";
$p: "padding";
$t: "top";
$r: "right";
$b: "bottom";
$l: "left";
$step: 5;  // it provides interval gap

// Global margin adjustment classes for 0-60. interval of 5px
@for $i from 0 through 60 / $step {
  .m#{$i*$step} {
    #{$m}: #{$i*$step}px;
  }

  .mt#{$i*$step} {
    #{$m}-#{$t}: #{$i*$step}px;
  }

  .mr#{$i*$step} {
    #{$m}-#{$r}: #{$i*$step}px;
  }

  .mb#{$i*$step} {
    #{$m}-#{$b}: #{$i*$step}px;
  }

  .ml#{$i*$step} {
    #{$m}-#{$l}: #{$i*$step}px;
  }

  .mtb#{$i*$step} {
    #{$m}-#{$t}: #{$i*$step}px;
    #{$m}-#{$b}: #{$i*$step}px;
  }

  .mrl#{$i*$step} {
    #{$m}-#{$r}: #{$i*$step}px;
    #{$m}-#{$l}: #{$i*$step}px;
  }
}
// END Global margin adjustment classes for 0-60.

// Global padding adjustment classes for 0-60.
@for $i from 0 through 60 / $step {
  .p#{$i*$step} {
    #{$p}: #{$i*$step}px;
  }
  .pt#{$i*$step} {
    #{$p}-#{$t}: #{$i*$step}px;
  }

  .pr#{$i*$step} {
    #{$p}-#{$r}: #{$i*$step}px;
  }

  .pb#{$i*$step} {
    #{$p}-#{$b}: #{$i*$step}px;
  }

  .pl#{$i*$step} {
    #{$p}-#{$l}: #{$i*$step}px;
  }

  .ptb#{$i*$step} {
    #{$p}-#{$t}: #{$i*$step}px;
    #{$p}-#{$b}: #{$i*$step}px;
  }

  .prl#{$i*$step} {
    #{$p}-#{$l}: #{$i*$step}px;
    #{$p}-#{$r}: #{$i*$step}px;
  }
}

// END Global padding adjustment classes for 0-60.
