@import "../../../../assets/stylesheet/global/varibale";
@import "../../../../assets/stylesheet/global/mixin";

.user-info {
  align-items: center;
  display: inline-flex;
  padding-right: 15px;

  @include break-point(tablet-view) {
    position: absolute;
    top: 20px;
    right: 0;

    .notification {
      &.update {
        margin-right: 15px;
      }
    }

    .detail {
      padding-right: 15px!important;
    }
  }

  .detail {
    cursor: pointer;
    line-height: 1;
    padding-right: 30px;
    position: relative;

    @include break-point(mobile-view) {
      padding-right: 0;

      .icon {
        top: 0!important;
      }
    }

    .greeting {
      color: $light-font;
    }

    .name {
      font-family: VoltePlay_Medium, sans-serif;
      font-size: 16px;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @include break-point(mobile-view) {
        border-top: 1px solid $grey;
        margin-top: 5px;
        margin-right: 10px;
        max-width: 100%;
        padding-top: 2px;
        padding-left: 0;
      }

      @include break-point(ie) {
        @include ie-text-gradient;
      }
    }

    .icon {
      line-height: 0;
      position: absolute;
      top: 13px;
      right: -15px;
    }

    .user-dropdown {
      background-color: $white;
      border: solid 2px $white;
      border-radius: 5px;
      box-shadow: 0 0 24.5px 0 #e0cdcc;
      cursor: default;
      display: none;
      font-size: 16px;
      margin-bottom: 0;
      padding: 20px;
      position: absolute;
      top: 45px;
      right: -25px;
      width: 180px;
      z-index: 99;

      @include break-point(mobile-view) {
        min-width: 120px;
        padding: 10px;
        top: 25px;
        width: 200px;
      }

      .option {
        align-items: center;
        cursor: pointer;
        display: inline-flex;
        margin-bottom: 25px;

        @include break-point(mobile-view) {
          display: block;
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .signout-icon {
          line-height: 0;
          margin-right: 18px;
          vertical-align: top;

          @include break-point(mobile-view) {
            margin-right: 0;
            margin-left: 15px;
            transform: rotate(180deg);
          }
        }

        a {
          color: $font-default-color;

          &:hover {
            color: $pink-color;
          }
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    &.open {
      .user-dropdown {
        display: inline-block;
      }

      .icon {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }

  .user-img {
    align-items: center;
    border: solid 4px #f5f4f4;
    border-radius: 50%;
    display: inline-flex;
    height: 46px;
    margin-right: 10px;
    width: 46px;

    @include break-point(mobile-view) {
      height: 40px;
      margin-right: 0;
      width: 40px;
    }

    img {
      height: 38px;
      width: 38px;

      @include break-point(mobile-view) {
        height: 32px;
        width: 32px;
      }
    }
  }

  .notification {
    cursor: pointer;
    margin-right: 35px;
    position: relative;

    @include break-point(mobile-view) {
      margin-right: 15px;
    }

    img {
      height: 21px;
      width: 19px;
    }
    
    &.unread-notication{

      &:after {
        background-color: $pink-color;
        border-radius: 50%;
        content: '';
        height: 8px;
        position: absolute;
        top: -1px;
        right: 0;
        width: 8px;
      }
    }
  }
}

.user-details {
  background-color: $white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .5);
  font-size: 16px;
  margin-right: auto;
  margin-left: auto;
  max-width: 500px;
  width: 100%;

  .title {
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 18px;
  }
}
