@import "../../../assets/stylesheet/global/varibale";
@import "../../../assets/stylesheet/global/mixin";

.header {
  padding-top: 20px;
  padding-bottom: 30px;
  position: relative;
  z-index: 99;

  @include break-point(mobile-view) {
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .inner {
    display: flex;
    align-items: center;

    @include break-point(tablet-view) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 10px;
    }
  }

  .logo {
    cursor: pointer;
    // height: 41px;
    height:auto;
    width: 154px;

    @include break-point(tablet-view) {
      height: 30px;
      margin-top: 10px;
      margin-right: 30px;
    }

    @include break-point(mobile-view) {
      height: 28px;
      width: 110px;
    }
  }

  .link-btn {
    a {
      color: $white;

      &:hover {
        color: $grey;
      }
    }

    @include break-point(tablet-view) {
      font-size: 14px;
      height: 40px;
      margin-top: 10px;
    }
  }
}

.ml-auto {
  margin-left: auto;
}
