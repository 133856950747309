@import "../global/varibale";
@import "../global/mixin";

.list-tab {
  display: flex;
  margin-bottom: 50px;

  @include break-point(mobile-view) {
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
  }

  li {
    border-bottom: 4px solid transparent;
    color: $light-font;
    cursor: pointer;
    font-family: VoltePlay_Medium, sans-serif;
    font-size: 35px;
    list-style-type: none;
    margin-right: 50px;
    padding-right: 5px;
    padding-bottom: 2px;
    position: relative;

    @include break-point(mobile-view) {
      font-size: 25px;
    }

    &.active {
      @include gradient-font-style;
      background-color: transparent;
      &:after {
        // background: linear-gradient(to right, rgba(239,133,34,1) 0%, rgba(226,30,37,1) 29%, rgba(158,39,120,1) 62%, rgba(25,72,147,1) 100%);
        content: '';
        background-color:#E10092;
        position: absolute;
        left: 0;
        bottom: -6px;
        height: 2px;
        width: 100%;
      }
    }

    &:hover {
      color: $font-default-color;
    }
  }
}